// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/chris/Documents/csfrequency/csfrequency.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/Users/chris/Documents/csfrequency/csfrequency.com/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cloud-development-js": () => import("/Users/chris/Documents/csfrequency/csfrequency.com/src/pages/cloud-development.js" /* webpackChunkName: "component---src-pages-cloud-development-js" */),
  "component---src-pages-hire-us-js": () => import("/Users/chris/Documents/csfrequency/csfrequency.com/src/pages/hire-us.js" /* webpackChunkName: "component---src-pages-hire-us-js" */),
  "component---src-pages-index-js": () => import("/Users/chris/Documents/csfrequency/csfrequency.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("/Users/chris/Documents/csfrequency/csfrequency.com/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-web-mobile-development-js": () => import("/Users/chris/Documents/csfrequency/csfrequency.com/src/pages/web-mobile-development.js" /* webpackChunkName: "component---src-pages-web-mobile-development-js" */)
}

exports.data = () => import("/Users/chris/Documents/csfrequency/csfrequency.com/.cache/data.json")

