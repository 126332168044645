// @flow
const analyticsId = 'UA-121401972-1';

export const analyticsSrc = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;

export const analyticsJS = `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${analyticsId}');`;

export const pageView = (location: Object) => {
  const page = location
    ? location.pathname + location.search + location.hash
    : undefined;
  window.gtag('config', analyticsId, {
    page_path: page,
  });
};
